export const GENERATORS = {
  rec: {
    includeAll: [
      'owner',
      'operator',
      'reporting_entity',
      'issue_to_account',
      'generation_validation_curves',
      'generator_fuels.fuel_source.fuel_type',
      'generator_fuels.eligibilities',
      'generator_fuels.eligibility_bonds',
      'generator_fuels.eligibility_bonds.eligibility',
      'submission_user',
      'interconnected_utility',
      'balancing_authority',
      'organization',
      'generator_aggregate',
      'generator_aggregate.qre',
      'wregis_thermal_generator',
      'wregis_thermal_generator.reporting_entity',
      'main_generator',
      'notes',
      'notes.user',
    ],
    includeUniqueBy: {
      'contacts.name': ['owner'],
      'users.email': ['submission_user'],
      'fuel_types.name': ['generator_fuels.fuel_source.fuel_type'],
      'fuel_sources.description': ['generator_fuels.fuel_source'],
      'eligibilities.long_name': ['generator_fuels.eligibilities'],
      'reporting_entities.name': ['reporting_entity'],
      'generator_aggregates.reporting_unit_id': ['generator_aggregate'],
      'organizations.name': ['organization'],
      'generator_aggregates.meter_id': ['generator_aggregate'],
    },
  },
  rtc: {
    includeAll: [
      'owner',
      'operator',
      'third_party_engineering_verificator',
      'reporting_entity',
      'issue_to_account',
      'generator_fuels.feedstock.thermal_resource',
      'generator_fuels.eligibilities',
      'generator_fuels.eligibility_bonds',
      'generator_fuels.eligibility_bonds.eligibility',
      'generator_fuels.carbon_pathways',
      'submission_user',
      'organization',
      'notes',
      'notes.user',
    ],
    includeUniqueBy: {
      'contacts.name': ['owner'],
      'users.email': ['submission_user'],
      'fuel_types.name': ['generator_fuels.feedstock.thermal_resource'],
      'fuel_sources.description': [
        'generator_fuels.feedstock.thermal_resource',
      ],
      'eligibilities.long_name': ['generator_fuels.eligibilities'],
      'reporting_entities.name': ['reporting_entity'],
      'organizations.name': ['organization'],
    },
  },
};

export const GENERATORS_FIELDS = {
  rec: {
    notes: ['content', 'visibility', 'created_at', 'user'],
    user: ['email'],
    contacts: ['name'],
    organizations: ['name'],
    generation_validation_curve: [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ],
    generator_fuels: [
      'fuel_source',
      'eligibility_bonds',
      'remainder_adjustments',
    ],
    fuel_source: ['fuel_type'],
    eligibility_bonds: ['eligibility', 'eligible', 'details'],
    eligibility: ['short_name', 'long_name', 'slug'],
    interconnected_utility: ['name', 'state', 'ownership_type', 'nerc_regions'],
    balancing_authority: ['name', 'code'],
  },
  rtc: {
    notes: ['content', 'visibility', 'created_at', 'user'],
    user: ['email'],
    contacts: ['name'],
    organizations: ['name'],
    generator_fuels: ['feedstock', 'eligibility_bonds', 'autogenerated'],
    feedstock: ['thermal_resource'],
    eligibility_bonds: ['eligibility', 'eligible'],
    eligibility: ['short_name', 'long_name', 'slug'],
  },
};

export const GENERATOR_DOCUMENTS = {
  rec: {
    includeAll: ['generator.organization', 'generator'],
    includeUniqueBy: {
      'organizations.name': ['generator.organization'],
      'organizations.friendly_id': ['generator.organization'],
      'generators.facility_name': ['generator'],
      'generators.mrets_id': ['generator'],
      'generators.name': ['generators'],
    },
  },
  rtc: {
    includeAll: ['generator.organization', 'generator'],
    includeUniqueBy: {
      'organizations.name': ['generator.organization'],
      'organizations.friendly_id': ['generator.organization'],
      'generators.facility_name': ['generator'],
      'generators.mrets_id': ['generator'],
      'generators.name': ['generators'],
    },
  },
};

export const GENERATOR_DOCUMENTS_FIELDS = {
  rec: {
    organization: ['name', 'friendly_id'],
    generator: ['facility_name', 'mrets_id'],
  },
};

export const GENERATOR_DETAIL_FIELDS = {
  rec: {
    organizations: ['name'],
    generator_aggregate: [
      'meter_id',
      'mrets_id',
      'last_generator_split',
      'created_at',
      'updated_at',
      'organization',
      'qre',
    ],
    contacts: [
      'job_title',
      'name',
      'street_1',
      'street_2',
      'country',
      'state_province',
      'city',
      'postal_code',
      'phone',
      'email',
      'url',
    ],
    generator_fuels: [
      'label',
      'remainder_mwh',
      'fuel_source',
      'eligibilities',
      'eligibility_bonds',
      'remainder_adjustments',
      'use_new_allocations_attributes',
    ],
    interconnected_utility: ['name', 'state', 'ownership_type', 'nerc_regions'],
    balancing_authority: ['name', 'code'],
    fuel_source: ['fuel_type'],
    eligibilities: ['short_name', 'long_name', 'slug', 'details_schema'],
    eligibility_bonds: [
      'details',
      'eligibility',
      'eligibility_bondable',
      'eligible',
    ],
    notes: ['content', 'visibility', 'created_at', 'user'],
    user: ['email'],
  },
  rtc: {
    organizations: ['name'],
    contacts: [
      'job_title',
      'name',
      'street_1',
      'street_2',
      'country',
      'state_province',
      'city',
      'postal_code',
      'phone',
      'email',
      'url',
    ],
    generator_fuels: [
      'label',
      'remainder_mwh',
      'autogenerated',
      'feedstock',
      'eligibilities',
      'eligibility_bonds',
      'carbon_pathways',
    ],
    feedstock: ['thermal_resource'],
    eligibilities: ['short_name', 'long_name', 'slug', 'details_schema'],
    eligibility_bonds: [
      'details',
      'eligibility',
      'eligibility_bondable',
      'eligible',
    ],
    carbon_pathways: [
      'name',
      'tool_name',
      'carbon_intensity',
      'start_date',
      'end_date',
      'injection_point',
      'endpoint',
      'endpoint_description',
      'document_url',
      'document_name',
      'private_document_url',
      'private_document_name',
    ],
    notes: ['content', 'visibility', 'created_at', 'user'],
    user: ['email'],
  },
};

export const GENERATOR_FUELS_INCLUDE = {
  rec: [
    'eligibilities',
    'eligibility_bonds.eligibility',
    'fuel_source',
    'fuel_source.fuel_type',
    'remainder_adjustments.certificate',
    'remainder_adjustments.generation_entry',
    'remainder_adjustments.generation_entry.parent_generation_entry',
    'remainder_adjustments.notes',
    'generator',
  ],
  rtc: [
    'eligibilities',
    'eligibility_bonds.eligibility',
    'carbon_pathways',
    'feedstock',
    'feedstock.thermal_resource',
    'generator',
  ],
};

export const GENERATOR_FUELS_FIELDS = {
  rec: {
    certificates: ['quantity', 'serial_number_base'],
    generation_entries: [
      'date_of_action',
      'generation_period_start',
      'fuel_allocation_type',
      'adjustment_type',
      'fuel_allocation',
      'quantity',
      'parent_generation_entry',
      'status',
      'history_order',
    ],
    generator_fuel_remainder_adjustments: [
      'certificate',
      'generation_entry',
      'quantity',
      'quantity_unit',
      'remainder_after',
      'remainder_before',
      'remainder_after_unit',
      'remainder_before_unit',
      'created_at',
      'notes',
      'current_allocation',
      'prev_allocation',
    ],
    generator: ['mrets_id'],
  },
  rtc: {},
};
export const GENERATOR_FUELS_WITH_PARENT_FIELDS = {
  ...GENERATOR_FUELS_FIELDS,
  rec: {
    ...GENERATOR_FUELS_FIELDS.rec,
    generation_entries: [
      ...GENERATOR_FUELS_FIELDS.rec.generation_entries,
      'valid_parent_generation_entry_data',
    ],
  },
};
