import BaseAPI from '../base';
import { formatQueryData, getFieldsValues, getIncludeValues } from '../helpers';
import { FUEL_TYPE } from '../constants';
import {
  GENERATORS,
  GENERATORS_FIELDS,
  GENERATOR_DETAIL_FIELDS,
  GENERATOR_FUELS_INCLUDE,
  GENERATOR_DOCUMENTS,
  GENERATOR_DOCUMENTS_FIELDS,
  GENERATOR_FUELS_FIELDS,
  GENERATOR_FUELS_WITH_PARENT_FIELDS,
} from './generator.constants';
import {
  GENERATOR_UNITS,
  GENERATOR_UNITS_FIELDS,
} from '../generatorUnit/generatorUnit.constants';

class GeneratorAPI extends BaseAPI {
  constructor(jsonApiInstance, axiosInstance, resourceType) {
    super(jsonApiInstance, axiosInstance, resourceType);
    this.fuelTypeKey = FUEL_TYPE.models[resourceType].fuel_type;
    this.fuelSourceKey = FUEL_TYPE.models[resourceType].fuel_source;
  }

  async getGenerators(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generator', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        GENERATORS[this.resourceType],
      ),
      fields: getFieldsValues(
        newQueryData.unique_by,
        GENERATORS_FIELDS[this.resourceType],
        this.resourceType,
      ),
    });

    return response;
  }

  async getGenerator(generatorId) {
    const response = await this.jsonApi.find('generator', generatorId, {
      include: GENERATORS[this.resourceType].includeAll,
      fields: GENERATOR_DETAIL_FIELDS[this.resourceType],
    });

    return response;
  }

  async getGeneratorStatus(generatorId) {
    const response = await this.jsonApi.find('generator', generatorId, {
      fields: { generator: ['status'] },
    });

    return response;
  }

  async getGeneratorUnits(generatorId, queryData) {
    const response = await this.jsonApi
      .one('generator', generatorId)
      .relationships('generator_units')
      .get({
        ...queryData,
        include: GENERATOR_UNITS.includeAll,
        fields: GENERATOR_UNITS_FIELDS[this.resourceType],
      });

    return response;
  }

  async getGeneratorsBasicInfo(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generator', {
      ...newQueryData,
      fields: {
        generators: ['name', 'facility_name'],
      },
    });
    return response;
  }

  async cloneGenerator(generatorId, name) {
    const response = await this.axios.post(
      this.buildUrl(`/generators/${generatorId}/clone`),
      { name },
    );

    return response;
  }

  async createGenerator(payload) {
    return this.jsonApi.create('generator', payload, {
      include: GENERATORS[this.resourceType].includeAll,
    });
  }

  async updateGenerator(id, payload) {
    return this.jsonApi.update(
      'generator',
      { id, ...payload },
      {
        include: GENERATORS[this.resourceType].includeAll,
      },
    );
  }

  async createGeneratorContact(generatorId, type, payload) {
    const { data: contact } = await this.jsonApi.create('contact', payload);

    await this.jsonApi
      .one('generator', generatorId)
      .relationships(type)
      .patch({ id: contact.id });

    return contact;
  }

  async updateGeneratorContact(payload) {
    const { data: contact } = await this.jsonApi.update('contact', payload);

    return contact;
  }

  async approveReview(generatorId) {
    const res = await this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generator',
        id: generatorId,
      })}/accept_review`,
      'generator',
      { status: 'active' },
    );

    return res;
  }

  async createGeneratorFuel(payload) {
    return this.jsonApi.create('generator_fuel', payload, {
      include: GENERATOR_FUELS_INCLUDE[this.resourceType],
    });
  }

  async getGeneratorFuelWithParent(fuelId) {
    return this.jsonApi.find('generator_fuel', fuelId, {
      include: GENERATOR_FUELS_INCLUDE[this.resourceType],
      fields: GENERATOR_FUELS_WITH_PARENT_FIELDS[this.resourceType],
    });
  }

  async getGeneratorFuel(fuelId) {
    return this.jsonApi.find('generator_fuel', fuelId, {
      include: GENERATOR_FUELS_INCLUDE[this.resourceType],
      fields: GENERATOR_FUELS_FIELDS[this.resourceType],
    });
  }

  async getGeneratorFuels(queryData, generatorId) {
    const newQueryData = formatQueryData(queryData);
    const url = `${this.jsonApi.urlFor({
      model: 'generator',
      id: generatorId,
    })}/generator_fuels`;

    return this.jsonApi.customRequest('GET', url, 'generator_fuel', {
      ...newQueryData,
      include: [`${this.fuelSourceKey}.${this.fuelTypeKey}`],
    });
  }

  async getGeneratorFuelsWithEligibilities(queryData, generatorId) {
    const newQueryData = formatQueryData(queryData);
    const url = `${this.jsonApi.urlFor({
      model: 'generator',
      id: generatorId,
    })}/generator_fuels`;

    return this.jsonApi.customRequest('GET', url, 'generator_fuel', {
      ...newQueryData,
      include: [
        `${this.fuelSourceKey}.${this.fuelTypeKey}`,
        'eligibility_bonds',
        'eligibilities',
      ],
    });
  }

  async updateGeneratorFuel(payload) {
    return this.jsonApi.update('generator_fuel', payload, {
      include: GENERATOR_FUELS_INCLUDE[this.resourceType],
    });
  }

  async deleteGeneratorFuel(id) {
    return this.jsonApi.destroy('generator_fuel', id);
  }

  async createEligibilityBond(payload) {
    return this.jsonApi.create('eligibility_bond', payload);
  }

  async updateEligibilityBond(payload) {
    return this.jsonApi.update('eligibility_bond', payload);
  }

  async deleteEligibilityBond(id) {
    return this.jsonApi.destroy('eligibility_bond', id);
  }

  async uploadGeneratorDocument(generatorId, documentData) {
    const form = new FormData();
    form.append('file', documentData.file);
    form.append('document_type', documentData.document_type);
    form.append('notes', documentData.notes || '');

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.post(
      this.buildUrl(`/generators/${generatorId}/document`),
      form,
      { headers },
    );

    return {
      ...response,
      data: response.data.data,
    };
  }

  async uploadDGGs(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/generators/import`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async getGeneratorsDocuments(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generator_documents', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        GENERATOR_DOCUMENTS[this.resourceType],
      ),
      fields: GENERATOR_DOCUMENTS_FIELDS[this.resourceType],
    });

    return response;
  }

  async getGeneratorDocuments(generatorId) {
    const response = await this.axios.get(
      this.buildUrl(`/generators/${generatorId}/generator_documents`),
    );

    let data = response.data.data || [];
    data = data.map(({ id, attributes }) => ({ id, ...attributes }));

    return {
      ...response,
      data,
    };
  }

  async deleteGeneratorDocument(docId) {
    return this.jsonApi.destroy('generator_document', docId);
  }

  async uploadGeneratorFacilityPhoto(generatorId, facilityPhotoFile) {
    const form = new FormData();
    form.append('file', facilityPhotoFile);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.post(
      this.buildUrl(`/generators/${generatorId}/image`),
      form,
      { headers },
    );

    return {
      ...response,
      data: response.data.url,
    };
  }

  async deleteGeneratorFacilityPhoto(generatorId) {
    return this.axios.delete(this.buildUrl(`/generators/${generatorId}/image`));
  }

  async createCarbonPathway(payload) {
    const form = new FormData();
    form.append('generator_fuel_id', payload.generatorFuelId);
    form.append('tool_name', payload.tool_name || '');
    form.append('name', payload.name || '');
    form.append('start_date', payload.start_date || '');
    form.append('end_date', payload.end_date || '');
    form.append('endpoint', payload.endpoint || '');
    form.append('injection_point', payload.injection_point || '');
    form.append('endpoint_description', payload.endpoint_description || '');
    form.append('carbon_intensity', payload.carbon_intensity || '');
    form.append('document', payload.document || '');
    form.append('private_document', payload.private_document || '');

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.post(
      this.buildUrl('/carbon_pathways'),
      form,
      { headers },
    );

    const jsonApiResource = this.jsonApi.fromAxios(response);

    return {
      ...response,
      data: jsonApiResource,
    };
  }

  async updateCarbonPathway(payload) {
    const form = new FormData();
    form.append('name', payload.name || '');
    form.append('end_date', payload.end_date || '');

    if (payload.document) {
      form.append('document', payload.document);
    }

    if (payload.private_document) {
      form.append('private_document', payload.private_document);
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.patch(
      this.buildUrl(`/carbon_pathways/${payload.id}`),
      form,
      { headers },
    );

    const jsonApiResource = this.jsonApi.fromAxios(response);

    return {
      ...response,
      data: jsonApiResource,
    };
  }
}

export default GeneratorAPI;
