export const RESOURCE_TYPES = {
  rec: 'rec',
  rtc: 'rtc',
};

export const REGIONS = {
  mrets: 'mrets',
  wregis: 'wregis',
  'M-RETS': 'mrets',
  WREGIS: 'wregis',
};

export const REGIONS_NAMES = {
  MRETS: 'M-RETS',
  WREGIS: 'WREGIS',
  mrets: 'M-RETS',
  wregis: 'WREGIS',
};

export const REGIONS_HOSTNAMES = {
  [REGIONS.wregis]: [
    'weccstakeholder.org',
    'wecc.org',
    'app.wregis.org',
    'region',
    'regionapp',
  ],
};

export const GEN_TYPES = {
  regular: 'regular',
  imported: 'imported',
  dgg: 'dgg',
  wregisThermal: 'wregis_thermal',
};

export const FUEL_TYPE_KEYS = {
  rec: 'fuel_type',
  rtc: 'thermal_resource',
};

export const FUEL_SOURCE_KEYS = {
  rec: 'fuel_source',
  rtc: 'feedstock',
};

export const monthValues = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const TIMEZONES = [
  {
    label: 'Hawaii Standard Time (UTC -10)',
    value: 'Hawaii Standard Time',
    hours: '-10',
  },
  {
    label: 'Alaska Standard Time (UTC -9)',
    value: 'Alaska Standard Time',
    hours: '-9',
  },
  {
    label: 'Pacific Standard Time (UTC -8)',
    value: 'Pacific Standard Time',
    hours: '-8',
  },
  {
    label: 'Mountain Standard Time (UTC -7)',
    value: 'Mountain Standard Time',
    hours: '-7',
  },
  {
    label: 'Central Standard Time (UTC -6)',
    value: 'Central Standard Time',
    hours: '-6',
  },
  {
    label: 'Eastern Standard Time (UTC -5)',
    value: 'Eastern Standard Time',
    hours: '-5',
  },
  {
    label: 'Atlantic Standard Time (UTC -4)',
    value: 'Atlantic Standard Time',
    hours: '-4',
  },
  {
    label: 'UTC',
    value: 'UTC',
    hours: '-0',
  },
];

export const STATES_PROVINCES_CODES = {
  // US States
  alabama: 'AL',
  alaska: 'AK',
  'american samoa': 'AS',
  arizona: 'AZ',
  arkansas: 'AR',
  'armed forces americas': 'AA',
  'armed forces europe': 'AE',
  'armed forces pacific': 'AP',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  'district of columbia': 'DC',
  florida: 'FL',
  georgia: 'GA',
  guam: 'GU',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  'marshall islands': 'MH',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  'northern mariana islands': 'NP',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'puerto rico': 'PR',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  'us virgin islands': 'VI',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',

  // Canada Provinces
  alberta: 'AB',
  'british columbia': 'BC',
  manitoba: 'MB',
  'new brunswick': 'NB',
  'newfoundland and labrador': 'NF',
  'northwest territories': 'NT',
  'nova scotia': 'NS',
  nunavut: 'NU',
  ontario: 'ON',
  'prince edward island': 'PE',
  quebec: 'QC',
  saskatchewan: 'SK',
  yukon: 'YT',

  // United States Minor Outlying Islands Provinces
  'united states minor outlying i': 'UM',
};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const URL_REGEX =
  /^(https?|ftp|torrent|image|irc):\/\/(-\.)?([^\s/?.#]+\.?)+(\/[^\s]*)?$/i;
export const PHONE_REGEX =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,12}$/im;
