export const TRANSACTION_DETAIL_FIELDS = {
  rec: {
    accounts: ['id', 'name', 'organization'],
    certificates: [
      'eligibilities',
      'fuel_type',
      'generation_entry',
      'generator_fuel',
      'generator',
      'serial_number_base',
      'vintage_date',
    ],
    eligibilities: ['long_name', 'short_name', 'slug'],
    fuel_sources: ['description'],
    fuel_types: ['name'],
    generators: [
      'facility_name',
      'mrets_id',
      'name',
      'organization',
      'state_province',
    ],
    generator_fuels: ['fuel_source'],
    organizations: ['name'],
    user_transactions: [
      'retirement_type',
      'retirement_reason',
      'retired_to',
      'compliance_period',
      'transaction_type',
      'status',
      'notes',
      'retirement_kind',
      'ended_at',
      'started_at',
      'hourly_claim',
    ],
  },
  rtc: {
    accounts: ['id', 'name', 'organization'],
    carbon_pathways: ['id', 'name'],
    certificates: [
      'eligibilities',
      'generation_entry',
      'generator',
      'generator_fuel',
      'serial_number_base',
      'thermal_resource',
      'vintage_date',
      'carbon_pathways',
    ],
    eligibilities: ['long_name', 'short_name', 'slug'],
    feedstocks: ['description'],
    generators: [
      'facility_name',
      'generator_pipeline_connected',
      'mrets_id',
      'name',
      'organization',
      'state_province',
    ],
    generator_fuels: ['feedstock'],
    organizations: ['name'],
    thermal_resources: ['name'],
    user_transactions: [
      'retirement_type',
      'retirement_reason',
      'retired_to',
      'compliance_period',
      'transaction_type',
      'status',
      'notes',
      'retirement_kind',
      'ended_at',
      'started_at',
      'retired_quarter',
    ],
  },
};

export const TRANSACTION_DETAIL = {
  rec: {
    includeAll: [
      'certificate.eligibilities',
      'certificate.fuel_type',
      'certificate.generation_entry',
      'certificate.generator_fuel.fuel_source',
      'certificate.generator.organization',
      'from_account.organization',
      'from_organization',
      'to_account.organization',
      'to_organization',
      'user_transaction',
    ],
    includeUniqueBy: {
      'from_accounts.name': ['from_account'],
      'to_accounts.name': ['to_account'],
      'organizations.name': [
        'from_account.organization',
        'from_organization',
        'certificate.generator.organization',
        'certificate.eligibilities',
        'user_transaction',
      ],
      'to_organizations.name': ['to_organization', 'to_account.organization'],
      'user_transactions.transaction_type': ['user_transaction'],
      'user_transactions.retirement_reason': ['user_transaction'],
      'user_transactions.retired_to': ['user_transaction'],
      'user_transactions.compliance_period': ['user_transaction'],
      'user_transactions.retirement_type': ['user_transaction'],
      'user_transactions.status': ['user_transaction'],
      'fuel_sources.description': ['certificate.generator_fuel.fuel_source'],
      'fuel_types.name': ['certificate.fuel_type'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'generators.state_province': ['certificate.generator'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'retirement_options.main_reason': ['user_transaction.retirement_option'],
    },
  },
  rtc: {
    includeAll: [
      'carbon_pathway',
      'certificate.carbon_pathways',
      'certificate.eligibilities',
      'certificate.generation_entry',
      'certificate.generator_fuel.feedstock',
      'certificate.generator.organization',
      'certificate.thermal_resource',
      'from_account.organization',
      'from_organization',
      'to_account.organization',
      'to_organization',
      'user_transaction',
    ],
    includeUniqueBy: {
      'from_accounts.name': ['from_account'],
      'to_accounts.name': ['to_account'],
      'organizations.name': [
        'from_account.organization',
        'from_organization',
        'certificate.generator.organization',
        'user_transaction',
      ],
      'to_organizations.name': ['to_organization', 'to_account.organization'],
      'user_transactions.transaction_type': ['user_transaction'],
      'user_transactions.retirement_reason': ['user_transaction'],
      'user_transactions.retired_to': ['user_transaction'],
      'user_transactions.compliance_period': ['user_transaction'],
      'user_transactions.retirement_type': ['user_transaction'],
      'user_transactions.status': ['user_transaction'],
      'fuel_sources.description': ['certificate.generator_fuel.feedstock'],
      'fuel_types.name': ['certificate.thermal_resource'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'generators.state_province': ['certificate.generator'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'retirement_options.main_reason': ['user_transaction.retirement_option'],
    },
  },
};
