import BaseAPI from './base';
import { formatQueryData, getIncludeValues } from './helpers';

class ETagsAPI extends BaseAPI {
  async getETagQuantities(queryData) {
    const newQueryData = formatQueryData(queryData);
    const includes = {
      includeAll: [
        'etag',
        'etag.pse_code',
        'certificate_quantity',
        'certificate_quantity.certificate',
        'certificate_quantity.certificate.generator',
        'certificate_quantity.certificate.eligibilities',
        'certificate_quantity.certificate.generator.generator_fuels.eligibility_bonds.eligibility',
        'certificate_quantity.account',
        'certificate_quantity.account.organization',
      ],
      includeUniqueBy: {
        'organizations.name': ['certificate_quantity.account.organization'],
        'accounts.name': ['certificate_quantity.account'],
        'organization_account_types.name': ['certificate_quantity.account'],
        'accounts.account_type ': ['certificate_quantity.account'],
        'generators.mrets_id': ['certificate_quantity.certificate.generator'],
        'eligibilities.long_name': [
          'certificate_quantity.certificate.eligibilities',
        ],
        'etags.id': ['etag'],
        'etags.etag_id': ['etag'],
        'etags.load': ['etag'],
        'etags.load_control_area': ['etag'],
        'etags.generator_control_area': ['etag'],
        'etags.miscellaneous_token': ['etag'],
        'etags.generator_name': ['etag'],
        'accounts.account_type': ['certificate_quantity.account'],
      },
    };
    const params = {
      ...newQueryData,
      include: getIncludeValues(newQueryData.unique_by, includes),
    };

    const response = await this.jsonApi.findAll('etag_quantity', params);

    return response;
  }

  async getETags(queryData) {
    const newQueryData = formatQueryData(queryData);
    const includes = {
      includeAll: ['pse_code.organization'],
      includeUniqueBy: {
        'organizations.name': ['pse_code.organization'],
      },
    };

    const response = await this.jsonApi.findAll('etag', {
      ...newQueryData,
      include: getIncludeValues(newQueryData.unique_by, includes),
    });

    return response;
  }

  async createETagMatching(payload) {
    return this.jsonApi.create('etag_match', payload);
  }

  async postEtagUpload(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/user_transactions/import`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async postOatiUpload(formDataValues) {
    const form = new FormData();
    form.append('oati_file', formDataValues.file);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/etags/oati_upload`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  }
}

export default ETagsAPI;
