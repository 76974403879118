const name = 'generation_entry';

const shape = {
  organization_name: '',
  generation_period_start: '',
  generation_period_end: '',
  date_of_action: '',
  rollback_eligible: false,
  status: '',
  status_control: {},
  fuel_allocation: [],
  fuel_allocation_type: '',
  fuel_type_names: '',
  notes: '',
  fuel: '',
  quantity: '',
  quantity_unit: [],
  reported_source: '',
  adjustment_type: '',
  is_disputable_or_acceptable: '',
  history_order: '',
  user: {
    type: 'user',
    jsonApi: 'hasOne',
  },
  parent_generation_entry: {
    type: 'generation_entry',
    jsonApi: 'hasOne',
  },
  valid_parent_generation_entry_data: {
    fuel_allocation: [],
    fuel_allocation_type: '',
    quantity: 0,
    quantity_unit: [],
  },
  certificates: {
    type: 'certificates',
    jsonApi: 'hasMany',
  },
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  generator_aggregate: {
    type: 'generator_aggregates',
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organization',
    jsonApi: 'hasOne',
  },
  reporting_entity: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  generator_fuel_remainder_adjustments: {
    type: 'generator_fuel_remainder_adjustments',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
